'use strict';

if (window.NodeList && !NodeList.prototype.forEach) {
  NodeList.prototype.forEach = Array.prototype.forEach;
}

const body = document.querySelector('body');

// Main navigation toggle

const mainNavToggle = document.querySelector('.main-nav-toggle');
const mainNav = document.querySelector('.main-nav-outer');
const siteSearch = document.querySelector('.site-search');

mainNavToggle.addEventListener('click', function(){
  this.classList.toggle('is-selected');
  mainNav.classList.toggle('is-visible');
});

// Sub-nav toggle

// FAQs

let openCurrentDropdownList = '';
const dropdowns = document.querySelectorAll('.faq');
const toggles = document.querySelectorAll('.toggle-faq');

dropdowns.forEach(function (dropdown, index) {
  var currentDropdownToggle = dropdown.querySelector('.toggle-faq');
  var currentDropdownList = dropdown.querySelector('.faq__content');
  
    currentDropdownToggle.addEventListener('click', function(){
      
      // remove is-active from all here
      toggles.forEach(function (toggle, index) {
        toggle.classList.remove('is-selected');
      });
      
      var allDropdownLists = document.querySelectorAll('.faq__content');
      
      allDropdownLists.forEach(function(dropdownList, index) {
        dropdownList.classList.remove('is-open');
      });
      
      if(openCurrentDropdownList != currentDropdownList.id) {
        currentDropdownList.classList.add('is-open');
        // then add is-active to the clicked one
        currentDropdownToggle.classList.add('is-selected');
        openCurrentDropdownList = currentDropdownList.id;
      } else {
        openCurrentDropdownList = '';
      }
    });
    
});
